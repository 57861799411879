import { captureRemixErrorBoundaryError } from '@sentry/remix';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  json,
  useRouteError
} from '@remix-run/react';
import type { LinksFunction, LoaderFunction } from '@remix-run/cloudflare';
import './tailwind.css';
import { getAppConfig } from '@repo/core';
import { remixLoaderWrapper } from '@repo/remix-utils';
import { registerServerErrorLogging } from '@repo/utils';

// Links configuration
export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'
  }
];

// Loader function
export const loader: LoaderFunction = remixLoaderWrapper(async () => {
  const config = getAppConfig();
  registerServerErrorLogging(config.SENTRY_DSN);
  return json({
    ENV: {
      SENTRY_DSN: config.SENTRY_DSN // Replace with your actual config if needed
    }
  });
});

// Layout Component
export function Layout({
  children,
  env
}: {
  children: React.ReactNode;
  env: any;
}) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)};`
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

// Error Boundary
export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Error</title>
      </head>
      <body>
        <h1>Something went wrong</h1>
        <p>
          {error instanceof Error ? error.message : 'Unknown error occurred.'}
        </p>
        <Scripts />
      </body>
    </html>
  );
};

// App Component
export default function App() {
  const { ENV } = useLoaderData<any>();

  return (
    <Layout env={ENV}>
      <Outlet />
    </Layout>
  );
}
